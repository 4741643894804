import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
  ButtonWrap,
} from "./style";
import Button from "@ui/button";

const BoxSection = ({ data, layout, ...props }) => {
  return (
    <BoxSectionWrapper layout={layout} {...props} id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap className="boxWrap">
                {data &&
                  data.items?.map((el, index) => (
                    <BoxSectionWrapInner className="greyBox" key={index}>
                      <BoxSectionIconwrap>
                        {el.images && el.images[0] && (
                          <Image
                            src={el?.images[0]?.src}
                            alt={el?.images[0]?.alt || "Icon"}
                            title={el.images[0]?.alt || "Icon"}
                          />
                        )}
                      </BoxSectionIconwrap>
                      <BoxSectionTextWrap>
                        <Heading as="h6" className="text-white">
                          {el.subject}
                        </Heading>
                        <Heading as="h4" className="text-white">
                          {el.title}
                        </Heading>
                        <Text>{el.description}</Text>
                      </BoxSectionTextWrap>
                    </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>
          {data?.buttons?.map(({ id, path, content, ...rest }) => (
            <Row key={id}>
              <Col>
                <ButtonWrap>
                  <Button key={id} path={path} {...rest}>
                    {content}
                  </Button>
                </ButtonWrap>
              </Col>
            </Row>
          ))}
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
