import styled, { css, device, themeGet } from "@styled";
import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";

export const BoxSectionWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  position: relative;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  ${device.medium} {
    padding: 166px 0;
  }
  .section-title {
    p {
      color: #fff;
    }
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding-top: 170px;
      background: url(${blueWaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    `}

  ${(props) =>
    props.layout === 3 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}
  ${(props) =>
    props.layout === 4 &&
    css`
      padding-top: 170px;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}
  ${(props) =>
    props.layout === 5 &&
    css`
      background: #f8f8f8;
    `}
  ${(props) =>
    props.layout === 6 &&
    css`
      background: url(${blueWaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -100px;
    `}
  ${(props) =>
    props.layout === 7 &&
    css`
      padding-top: 170px;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -110px;
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}

    ${(props) =>
    props.layout === 8 &&
    css`
        background: linear-gradient(-33deg, #fa4f19 0%, #de5307 99%, #de5307 100%);
        .section-title {
          h6 {
            color: #fff;
          }
          h2 {
            border-color: #fff;
          }
          p {
            color: #fff;
          }
        }
      `}

      ${(props) =>
    props.layout === 9 &&
    css`
          padding: 60px 0;

          ${device.medium} {
            padding: 100px 0;
          }
          
          background: #fff;
          .section-title {
            h6 {
              color: #fa4f19;
            }
            h2 {
              border-color: #fa4f19;
            }
            p {
              color: #212121;
            }
          }

          .greyBox {
            background-color: rgba(255,138,101,0);
            border: 1px solid rgba(210,210,210,0.55);

            &:hover {
              box-shadow: 0 0 40px -10px rgb(0 0 0 / 10%);
            }

            .gatsby-image-wrapper {
              margin: 10px 0 20px;
            }

            h4 {
              color: #343434 !important;
            }

            p {
              color: #343434 !important;
            }
          }
      `}

      ${(props) =>
    props.layout === 10 &&
    css`
          padding: 60px 0;
          
          .boxWrap {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
            
            ${device.medium} {
              grid-template-columns: repeat(2, 1fr);
            }
            
            ${device.xlarge} {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          ${device.medium} {
            padding: 100px 0;
          }
          
          background: #fff;
          .section-title {
            h6 {
              color: #fa4f19;
            }
            h2 {
              border-color: #fa4f19;
            }
            p {
              color: #212121;
            }
          }

          .greyBox {
            background-color: rgba(255,138,101,0);
            border: 1px solid rgba(210,210,210,0.55);
            padding: 40px 24px;

            ${device.xlarge} {
              padding: 50px 40px;
            }

            &:hover {
              box-shadow: 0 0 40px -10px rgb(0 0 0 / 10%);
            }

            .gatsby-image-wrapper {
              margin: 10px 0 20px;
            }

            h4 {
              color: #343434 !important;
            }

            p {
              color: #343434 !important;
            }
          }
      `}
      ${(props) =>
    props.layout === 11 &&
    css`
          padding: 60px 0;
          
          .boxWrap {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
            
            ${device.medium} {
              grid-template-columns: repeat(2, 1fr);
            }
            
            ${device.xlarge} {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          ${device.medium} {
            padding: 100px 0;
          }
          
          background: linear-gradient(
            320deg,
            ${themeGet("colors.secondaryDark")},
            ${themeGet("colors.secondary")}
          );
          .section-title {
            h6 {
              color: #fa4f19;
            }
            h2 {
              border-color: #fa4f19;
            }
            p {
              color: #212121;
            }
          }

          .greyBox {
            // background-color: rgba(255,138,101,0);
            border: 1px solid rgba(210,210,210,0.55);
            padding: 40px 24px;

            ${device.xlarge} {
              padding: 50px 40px;
            }

            &:hover {
              box-shadow: 0 0 40px -10px rgb(0 0 0 / 10%);
            }

            .gatsby-image-wrapper {
              margin: 10px 0 20px;
            }

            h4 {
              color: #fff !important;
            }

            p {
              color: #fff !important;
              // opacity:0.5;
            }
          }
      `}
      ${(props) =>
    props.layout === 12 &&
    css`
      padding:40px 0 !important;
      ${device.medium}{
        padding:60px 0 !important;
      }
      ${device.large}{ 
        padding:80px 0 !important;
      }
      ${device.xlarge}{ 
        padding:110px 0 !important;
      }
    `}
`;

export const BoxSectionInner = styled.div``;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  padding: 40px 24px;
  border: 1px solid rgb(255 255 255 / 55%);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255 255 255 / 11%);
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
`;
export const BoxSectionIconwrap = styled.div``;
export const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
`;
export const BoxSectionTextWrap = styled.div`
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
`;
